import React from 'react';

const OutputDisplay = ({ output }) => {
  return (
    <div>
      <h3>Output:</h3>
      <pre>{output}</pre>
    </div>
  );
};

export default OutputDisplay;
