import FlashcardCreatorPage from './FlashcardCreatorPage';
import PDFViewerPage from './PDFViewerPage';

function FlashPDFPage() {
    return (
      <div className="FlashPDFPage">
        <FlashcardCreatorPage />
        <PDFViewerPage />
      </div>
    );
  }
  
  export default FlashPDFPage;
  