import React, { useState } from 'react';

const Flashcard = ({ flashcards }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);

  // Go to the next card
  const nextFlashcard = () => {
    setIsFlipped(false);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % flashcards.length);
  };

  // Go to the previous card
  const prevFlashcard = () => {
    setIsFlipped(false);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + flashcards.length) % flashcards.length);
  };

  // Flip the card
  const flipCard = () => {
    setIsFlipped(!isFlipped);
  };

  const currentFlashcard = flashcards[currentIndex];

  return (
    <div className="flashcard-component">
      <div className="flashcard-box" onClick={flipCard}>
        {isFlipped ? (
          <div className="flashcard-back">{currentFlashcard.back}</div>
        ) : (
          <div className="flashcard-front">{currentFlashcard.front}</div>
        )}
      </div>

      <div className="flashcard-navigation">
        <button onClick={prevFlashcard}>Previous</button>
        <button onClick={flipCard}>Flip</button>
        <button onClick={nextFlashcard}>Next</button>
      </div>
    </div>
  );
};

export default Flashcard;
