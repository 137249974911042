// src/pages/UnitsPage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, getDocs, doc, getDoc, setDoc } from 'firebase/firestore';
import './UnitsPage.css';

const UnitsPage = () => {
  const [units, setUnits] = useState([]);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getFirestore();

  useEffect(() => {
    const fetchUnits = async () => {
      const unitsCollection = collection(db, 'units');
      const unitsSnapshot = await getDocs(unitsCollection);
      const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setUnits(unitsList);
    };

    fetchUnits();
  }, [db]);

  const handleSelectUnit = (unitCode) => {
    setSelectedUnits((prevSelectedUnits) => {
      if (prevSelectedUnits.includes(unitCode)) {
        return prevSelectedUnits.filter(code => code !== unitCode);
      } else {
        return [...prevSelectedUnits, unitCode];
      }
    });
  };

  const handleSubmit = async () => {
    const user = auth.currentUser;
    if (user) {
      const userUnitsRef = collection(doc(db, 'users', user.uid), 'units');
      
      try {
        await Promise.all(selectedUnits.map(async (unitCode) => {
          const unitRef = doc(db, 'units', unitCode);
          const unitSnapshot = await getDoc(unitRef);
          
          if (unitSnapshot.exists()) {
            await setDoc(doc(userUnitsRef, unitCode), unitSnapshot.data());
          }
        }));
  
        navigate('/dashboard'); // Navigate to the user's dashboard or another appropriate page
      } catch (error) {
        console.error('Error saving selected units:', error);
      }
    } else {
      console.error('No user is signed in');
    }
  };
  

  return (
    <div className="units-container">
      <h1>Select Units</h1>
      <ul>
        {units.map((unit) => (
          <li key={unit.unit_code} onClick={() => handleSelectUnit(unit.unit_code)} className={selectedUnits.includes(unit.unit_code) ? 'selected' : ''}>
            {/* <div className="unit-name">{unit.unit_code}</div> */}
            {unit.unit_full && <div className="unit-full">{unit.unit_full}</div>}
          </li>

        ))}
      </ul>
      <button onClick={handleSubmit}>Save Selected Units</button>
    </div>
  );
};

export default UnitsPage;
