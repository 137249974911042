// src/components/FlashcardEntryComponent.js
import React from 'react';

const FlashcardEntryComponent = ({ flashcardContent, setFlashcardContent }) => {
  return (
    <div style={{ flex: 1, overflow: 'auto', padding: '1rem', borderLeft: '1px solid #ccc' }}>
      <h3>Flashcard Entry</h3>
      <textarea
        value={flashcardContent}
        onChange={(e) => setFlashcardContent(e.target.value)}
        placeholder="Paste content here"
        style={{ width: '100%', height: '80%' }}
      />
    </div>
  );
};

export default FlashcardEntryComponent;
