import React, { useEffect, useState } from 'react';
import Problem from './Problem';
import { useParams } from 'react-router-dom';

const ProblemPage = () => {
  const { problemId } = useParams();
  const [problemData, setProblemData] = useState(null);

  useEffect(() => {
    // Load the problem data based on the ID
    fetch('/problems.json')
      .then(response => response.json())
      .then(data => {
        const problem = data.find(p => p.id === parseInt(problemId));
        setProblemData(problem);
      });
  }, [problemId]);

  if (!problemData) {
    return <div>Loading...</div>;
  }

  return (
    <Problem
      title={problemData.title}
      description={problemData.description}
      initialCode={problemData.initialCode}
      defaultLanguage={problemData.defaultLanguage || 'javascript'}
      testCases={problemData.testCases || []}
    />
  );
};

export default ProblemPage;
