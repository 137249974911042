// src/components/SubmitButton.js
import React from 'react';

const SubmitButton = ({ onSubmit }) => {
  return (
    <div className="submit-button">
      <button onClick={onSubmit}>Submit Flashcards</button>
    </div>
  );
};

export default SubmitButton;
