// src/components/Navbar.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import SignInModal from './SignInModal';
import './Navbar.css';

const Navbar = () => {
  const [showModal, setShowModal] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const handleSignIn = () => {
    setShowModal(true);
  };

  return (
    <header className="navbar">
      <div className="logo-container">
        <img src="/favicon.ico" alt="Logo" className="logo" />
      </div>
      <nav className="nav-links">
      <a href="codesandbox" className="nav-link">CodeSandbox</a>

      <a href="codeeditor" className="nav-link">CodeEditor</a>

      <a href="flashpdf" className="nav-link">FlashPDF</a>
      <a href="flashcards" className="nav-link">Flashcard</a>
        <a href="#" className="nav-link">Courses</a>
        <a href="#" className="nav-link">Practice</a>
        <a href="#" className="nav-link">Roadmap</a>
        {currentUser ? (
          <img src={currentUser.profileImageURL} alt="User" className="user-image" />
        ) : (
          <button onClick={handleSignIn} className="nav-link button">Sign In</button>
        )}
      </nav>
      <SignInModal showModal={showModal} setShowModal={setShowModal} />
    </header>
  );
};

export default Navbar;
