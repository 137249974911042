// src/components/FlashcardEditor.js
import React, { useState } from 'react';

const FlashcardEditor = ({ flashcards, onFlashcardChange }) => {
  const handleInputChange = (index, field, value) => {
    const updatedFlashcards = [...flashcards];
    updatedFlashcards[index][field] = value;
    onFlashcardChange(updatedFlashcards);
  };

  return (
    <div className="flashcard-editor">
      <h2>Review and Edit Flashcards</h2>
      {flashcards.map((flashcard, index) => (
        <div key={index} className="flashcard">
          <input
            type="text"
            value={flashcard.question}
            onChange={(e) =>
              handleInputChange(index, 'question', e.target.value)
            }
          />
          <textarea
            value={flashcard.answer}
            onChange={(e) => handleInputChange(index, 'answer', e.target.value)}
          />
        </div>
      ))}
    </div>
  );
};

export default FlashcardEditor;
