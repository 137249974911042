// src/pages/FlashcardReviewPage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './FlashcardReviewPage.css';

const FlashcardReviewPage = () => {
  const { deckId } = useParams();
  const [flashcards, setFlashcards] = useState([]);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [showAnswer, setShowAnswer] = useState(false);
  const db = getFirestore();

  useEffect(() => {
    const fetchFlashcards = async () => {
      const flashcardsCollection = collection(db, 'decks', deckId, 'cards');
      const flashcardsSnapshot = await getDocs(flashcardsCollection);
      const flashcardsList = flashcardsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setFlashcards(flashcardsList);
    };

    fetchFlashcards();
  }, [db, deckId]);

  const handleNextCard = () => {
    setShowAnswer(false);
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % flashcards.length);
  };

  if (flashcards.length === 0) {
    return <p>Loading...</p>;
  }

  const currentCard = flashcards[currentCardIndex];

  return (
    <div className="review-container">
      <h1>Flashcard Review</h1>
      <div className="flashcard">
        <div className="question">{currentCard.question}</div>
        {showAnswer && <div className="answer">{currentCard.answer}</div>}
        <button onClick={() => setShowAnswer(!showAnswer)}>
          {showAnswer ? 'Hide Answer' : 'Show Answer'}
        </button>
        <button onClick={handleNextCard}>Next Card</button>
      </div>
    </div>
  );
};

export default FlashcardReviewPage;
