import React, { useState } from 'react';
import CodeEditor from '../components/CodeEditor';
import OutputDisplay from '../components/OutputDisplay';
import { runCode } from '../services/api';

const languages = {
  javascript: { id: 63, name: 'JavaScript', defaultCode: '// Write your JavaScript code here' },
  python: { id: 71, name: 'Python', defaultCode: '# Write your Python code here' },
  cpp: { id: 54, name: 'C++', defaultCode: '// Write your C++ code here' },
  // Add other languages as needed
};

const Problem = ({ title, description, initialCode, defaultLanguage, testCases }) => {
  const [language, setLanguage] = useState(defaultLanguage || 'javascript');
  const [code, setCode] = useState(initialCode || languages[language].defaultCode);
  const [output, setOutput] = useState('');

  const handleLanguageChange = (e) => {
    const selectedLanguage = e.target.value;
    setLanguage(selectedLanguage);
    setCode(languages[selectedLanguage].defaultCode); // Reset code to the default template for the selected language
  };

  const handleRun = async () => {
    const result = await runCode(code, languages[language].id);
    const actualOutput = result.stdout || result.stderr;
    console.log("Actual Output:", actualOutput); // Log the output
    setOutput(actualOutput);

    // Check if the output matches the expected output
    const passed = testCases.every(testCase => actualOutput.trim() === testCase.expectedOutput.trim());
    
    if (passed) {
      alert('Success! Your code produced the expected output.');
    } else {
      alert('Error: Your code did not produce the expected output.');
    }
  };

  return (
    <div>
      <h2>{title}</h2>
      <p>{description}</p>
      <label htmlFor="language-select">Choose Language: </label>
      <select id="language-select" value={language} onChange={handleLanguageChange}>
        {Object.keys(languages).map(lang => (
          <option key={lang} value={lang}>
            {languages[lang].name}
          </option>
        ))}
      </select>
      <CodeEditor code={code} setCode={setCode} language={language} />
      <button onClick={handleRun}>Run Code</button>
      <OutputDisplay output={output} />
    </div>
  );
};

export default Problem;
