// src/components/ExtractedTextComponent.js
import React from 'react';

const ExtractedTextComponent = ({ selectedText }) => {
  return (
    <div style={{ flex: 1, overflow: 'auto', padding: '1rem', borderRight: '1px solid #ccc' }}>
      <h3>Extracted Text</h3>
      <p>{selectedText}</p>
    </div>
  );
};

export default ExtractedTextComponent;
