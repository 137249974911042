// src/pages/flashcardpagecomponents/PDFViewerComponent.js
import React, { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewerComponent = ({ handleTextSelect }) => {
  const [pdfFile, setPdfFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      setPdfFile(fileURL);
    }
  };

  return (
    <div style={{ flex: 2, overflow: 'auto', padding: '1rem' }}>
      {!pdfFile ? (
        <div>
          <h3>Select a PDF file to view</h3>
          <input type="file" accept="application/pdf" onChange={handleFileChange} />
        </div>
      ) : (
        <div onMouseUp={handleTextSelect}>
          
          <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.0.279/build/pdf.worker.min.js`}>
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.3)',
                height: '100%',
              }}
            >
              <Viewer fileUrl={pdfFile} />
            </div>
          </Worker>
        </div>
      )}
    </div>
  );
};

export default PDFViewerComponent;
