// src/App.js
import React from 'react';
import CodeSandbox from '../components/CodeSandbox';



function CodeSandboxPage() {
  return (
    <div style={{ padding: '20px' }}>
      {/* <h1>React Code Sandbox</h1> */}
      <CodeSandbox />
    </div>
  );
}

export default CodeSandboxPage;
