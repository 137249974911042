// src/pages/FlashCardStudyScreen.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import './FlashCardStudyScreen.css';

const FlashCardStudyScreen = () => {
  const { unitId } = useParams();
  const [decks, setDecks] = useState([]);
  const [expandedDeckId, setExpandedDeckId] = useState(null);
  const db = getFirestore();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDecks = async () => {
      const decksCollection = collection(db, 'units', unitId, 'decks');
      const decksSnapshot = await getDocs(decksCollection);
      const decksList = decksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setDecks(decksList);
    };

    fetchDecks();
  }, [db, unitId]);

  const handleDeckClick = (deckId) => {
    setExpandedDeckId(expandedDeckId === deckId ? null : deckId);
  };

  const handleStartReview = (deckId) => {
    navigate(`/review/${deckId}`);
  };

  return (
    <div className="study-container">
      <h1>Study Unit</h1>
      <ul>
        {decks.map(deck => (
          <li key={deck.id}>
            <div className="deck-header" onClick={() => handleDeckClick(deck.id)}>
              {deck.name}
            </div>
            {expandedDeckId === deck.id && (
              <div className="deck-content">
                <p>{deck.description}</p>
                <button onClick={() => handleStartReview(deck.id)}>Start Review</button>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FlashCardStudyScreen;
