
// src/BouncingBalls.js
import React, { useEffect, useRef } from 'react';
import p5 from 'p5';

const BouncingBalls = () => {
  const sketchRef = useRef();

  useEffect(() => {
    const sketch = (p) => {
      let balls = [];

      p.setup = () => {
        p.createCanvas(window.innerWidth, window.innerHeight);
        for (let i = 0; i < 10; i++) {
          balls.push(new Ball(p.random(p.width), p.random(p.height), p.random(100, 200)));
        }
      };

      p.draw = () => {
        p.clear();
        for (let ball of balls) {
          ball.update();
          ball.display();
        }
      };

      class Ball {
        constructor(x, y, diameter) {
          this.x = x;
          this.y = y;
          this.diameter = diameter;
          this.speedX = p.random(1, 3);
          this.speedY = p.random(1, 3);
          this.gradient = p.drawingContext.createRadialGradient(
            0,
            0,
            0,
            0,
            0,
            this.diameter / 2
          );
          this.gradient.addColorStop(0, 'rgba(143, 207, 60, 0.8)');
          this.gradient.addColorStop(1, 'rgba(52, 73, 94, 0)');
        }

        update() {
          this.x += this.speedX;
          this.y += this.speedY;
          if (this.x > p.width - this.diameter / 2 || this.x < this.diameter / 2) {
            this.speedX *= -1;
          }
          if (this.y > p.height - this.diameter / 2 || this.y < this.diameter / 2) {
            this.speedY *= -1;
          }
        }

        display() {
          p.push();
          p.translate(this.x, this.y);
          p.noStroke();
          p.drawingContext.fillStyle = this.gradient;
          p.ellipse(0, 0, this.diameter);
          p.pop();
        }
      }
    };

    const p5Instance = new p5(sketch, sketchRef.current);

    return () => {
      p5Instance.remove();
    };
  }, []);

  return <div ref={sketchRef} className="bouncing-balls-background" />;
};

export default BouncingBalls;
