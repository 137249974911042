// src/components/PrivateRoute.js
import React from 'react';
// import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

function PrivateRoute({ element: Component, ...rest }) {
  const { currentUser } = useAuth();

  return currentUser ? <Component {...rest} /> : null; // or display a message
}

export default PrivateRoute;
