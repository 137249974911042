import React, { useEffect, useState } from "react";

const PythonPreview = ({ code }) => {
  const [output, setOutput] = useState("");
  const [pyodide, setPyodide] = useState(null);

  useEffect(() => {
    // Dynamically load Pyodide from the CDN
    const loadPyodide = async () => {
      const pyodideInstance = await window.loadPyodide({
        indexURL: "https://cdn.jsdelivr.net/pyodide/v0.21.3/full/",
      });
      setPyodide(pyodideInstance);
    };

    // Initialize Pyodide
    if (!pyodide) {
      loadPyodide();
    }
  }, [pyodide]);

  useEffect(() => {
    if (!pyodide) return;

    const runPythonCode = async () => {
      try {
        const result = await pyodide.runPythonAsync(code);
        setOutput(result);
      } catch (err) {
        setOutput(err.toString());
      }
    };

    runPythonCode();
  }, [code, pyodide]);

  return (
    <div>
      <h3>Python Output:</h3>
      <pre>{output}</pre>
    </div>
  );
};

export default PythonPreview;
