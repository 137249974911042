import React, { useState } from "react";
import { Editor } from "@monaco-editor/react";
import Preview from "./Preview"; // For JavaScript preview
import PythonPreview from "./PythonPreview"; // For Python execution

const CodeSandbox = () => {
  const [files, setFiles] = useState({
    "index.js": {
      language: "javascript",
      content: `
    const App = () => {
      return (
        <div>
          <h1>Hello, world!</h1>
          <p>This is a live preview of your code.</p>
        </div>
      );
    };

    ReactDOM.render(<App />, document.getElementById("root"));
  `,
    },
    "index.html": { language: "html", content: "<h1>Hello, World!</h1>" },
    "style.css": { language: "css", content: "body { background-color: lightblue; }" },
    "main.py": { language: "python", content: "print('Hello, World!')" },
  });

  const [activeFile, setActiveFile] = useState("index.js");

  const handleCodeChange = (newCode) => {
    setFiles((prevFiles) => ({
      ...prevFiles,
      [activeFile]: { ...prevFiles[activeFile], content: newCode },
    }));
  };

  return (
    <div style={{ display: "flex", height: "100vh" }}>
      {/* File Tabs */}
      <div style={{ width: "15%", borderRight: "1px solid #ccc" }}>
        {Object.keys(files).map((fileName) => (
          <div
            key={fileName}
            style={{
              padding: "10px",
              cursor: "pointer",
              backgroundColor: activeFile === fileName ? "#f0f0f0" : "white",
            }}
            onClick={() => setActiveFile(fileName)}
          >
            {fileName}
          </div>
        ))}
      </div>

      {/* Code Editor */}
      <div style={{ width: "50%", padding: "10px" }}>
        <Editor
          height="90vh"
          language={files[activeFile].language}
          value={files[activeFile].content}
          onChange={handleCodeChange}
        />
      </div>

      {/* Preview or Python Output */}
      <div style={{ width: "35%", border: "1px solid #ccc" }}>
        {files[activeFile].language === "javascript" ? (
          <Preview code={files[activeFile].content} />
        ) : files[activeFile].language === "python" ? (
          <PythonPreview code={files[activeFile].content} />
        ) : (
          <div>No preview available for {files[activeFile].language}</div>
        )}
      </div>
    </div>
  );
};

export default CodeSandbox;
