// src/components/ExtractedContent.js
import React from 'react';

const ExtractedContent = ({ content }) => {
  return (
    <div className="extracted-content">
      <h2>Extracted Content</h2>
      <p>{content}</p>
    </div>
  );
};

export default ExtractedContent;
