export const runCode = async (code, languageId, stdin = '') => {
    try {
      const response = await fetch('https://judge0-ce.p.rapidapi.com/submissions?base64_encoded=false&wait=true', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-RapidAPI-Host': 'judge0-ce.p.rapidapi.com',
          'X-RapidAPI-Key': process.env.REACT_APP_X_RAPIDAPI_KEY  // Access the API key from environment variables
        },
        body: JSON.stringify({
          source_code: code,
          language_id: languageId,
          stdin: stdin,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      return await response.json();
    } catch (error) {
      console.error('Failed to run code:', error);
      alert('Failed to run code. Please try again later.');
      return null;
    }
  };
  