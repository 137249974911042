import React, { useEffect, useRef } from "react";
import * as Babel from "@babel/standalone";

const Preview = ({ code }) => {
  const iframeRef = useRef(null);

  const compileCode = (code) => {
    try {
      // Compile the user's code using Babel
      return Babel.transform(code, { presets: ["es2015", "react"] }).code;
    } catch (error) {
      console.error("Compilation error:", error);
      return `console.error("${error.message}");`;
    }
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    // Compile the code
    const compiledCode = compileCode(code);

    // Template for the HTML in the iframe
    const htmlTemplate = `
      <html>
        <head>
          <script src="https://unpkg.com/react/umd/react.development.js"></script>
          <script src="https://unpkg.com/react-dom/umd/react-dom.development.js"></script>
        </head>
        <body>
          <div id="root"></div>
          <script>
            try {
              ${compiledCode}
            } catch (error) {
              document.body.innerHTML = '<pre style="color: red;">' + error.message + '</pre>';
            }
          </script>
        </body>
      </html>
    `;

    // Set the iframe's content using the srcdoc attribute
    iframe.srcdoc = htmlTemplate;
  }, [code]);

  return (
    <iframe
      ref={iframeRef}
      title="Preview"
      style={{ width: "100%", height: "100%", border: "none" }}
      sandbox="allow-scripts"
    />
  );
};

export default Preview;
