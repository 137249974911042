// src/components/SignInModal.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithPopup, GoogleAuthProvider, GithubAuthProvider } from 'firebase/auth';
import './SignInModal.css';

const SignInModal = ({ showModal, setShowModal }) => {
  const navigate = useNavigate();
  const auth = getAuth();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setShowModal(false);
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  const handleGithubSignIn = async () => {
    const provider = new GithubAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      setShowModal(false);
      navigate('/');
    } catch (error) {
      console.error(error);
    }
  };

  if (!showModal) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Sign In</h2>
        <button onClick={handleGoogleSignIn} className="sign-in-button">Sign in with Google</button>
        {/* <button onClick={handleGithubSignIn} className="sign-in-button">Sign in with GitHub</button> */}
        <button onClick={() => setShowModal(false)} className="close-button">Close</button>
      </div>
    </div>
  );
};

export default SignInModal;
