// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import UnitsPage from './pages/UnitsPage';
import UserInfoPage from './pages/UserInfoPage';
import DashboardPage from './pages/DashboardPage';
import FlashCardStudyScreen from './pages/FlashCardStudyScreen';
import FlashcardReviewPage from './pages/FlashcardReviewPage';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import FlashcardPage from './pages/FlashcardPage';  // Ensure the correct path to FlashcardPage
import PDFViewerPage from './pages/PDFViewerPage';  // Ensure the correct path to FlashcardPage
import FlashcardCreatorPage from './pages/FlashcardCreatorPage';
import FlashPDFPage from './pages/FlashPDFPage';
import CodeEditorPage from './pages/CodeEditorPage';
import ProblemPage from './pages/ProblemPage';
import CodeSandboxPage from './pages/CodeSandboxPage';



// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';
// import { gtag } from './utils/gtag';

// function usePageViews() {
//   const location = useLocation();

//   useEffect(() => {
//     // Check if gtag is available on the window object
//     if (typeof window.gtag !== 'undefined') {
//       window.gtag('config', 'G-XZD7YV2PE7', {
//         page_path: location.pathname,
//       });
//     }
//   }, [location]);
// }

function App() {
  // usePageViews();

  return (
    
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />

          <Route path="/codeeditor" element={<CodeEditorPage />} />
          <Route path="/problems/:problemId" element={<ProblemPage />} />
          <Route path="/codesandbox" element={<CodeSandboxPage />} />

          <Route path="/flashcards" element={<FlashcardPage />} />
          <Route path="/pdfviewer" element={<PDFViewerPage />} />
          <Route path="/flashcreator" element={<FlashcardCreatorPage />} />
          <Route path="/flashpdf" element={<FlashPDFPage />} />

          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/user-info" element={<UserInfoPage />} />
          <Route path="/units" element={<UnitsPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/study/:unitCode" element={<FlashCardStudyScreen />} />
          <Route path="/review/:deckId" element={<FlashcardReviewPage />} />


          {/* <Route path="/dashboard" element={<PrivateRoute element={DashboardPage} />} /> */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
