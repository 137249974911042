// src/components/FileUpload.js
import React from 'react';

const FileUpload = ({ onFileUpload }) => {
  return (
    <div className="file-upload">
      <input type="file" onChange={onFileUpload} />
    </div>
  );
};

export default FileUpload;
